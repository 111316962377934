import { createRouter, createWebHistory } from "vue-router"

import store from "../store/index"

const routes = [
  {
    path: "/",
    name: "home",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/HomeView.vue"),
    meta: { transition: "pages-fade" },
  },
  {
    path: "/about",
    name: "about",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
  {
    path: "/artists",
    name: "artists",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArtistsView.vue"),
  },
  {
    path: "/artists/:id",
    name: "artist",
    meta: { transition: "none" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ArtistView.vue"),
  },
  {
    path: "/exhibitions",
    name: "exhibitions",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ExhibitionsView.vue"),
  },
  {
    path: "/exhibitions/:id",
    name: "exhibition",
    meta: { transition: "none" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ExhibitionView.vue"),
  },
  {
    path: "/multimedia",
    name: "multimedia",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/MultimediaView.vue"),
  },
  {
    path: "/fairs",
    name: "fairs",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FairsView.vue"),
  },
  {
    path: "/fairs/:id",
    name: "fair",
    meta: { transition: "none" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/FairView.vue"),
  },
  {
    path: "/catalogue",
    name: "catalogue",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CatalogueView.vue"),
  },
  {
    path: "/contacts",
    name: "contacts",
    meta: { transition: "pages-fade" },
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ContactsView.vue"),
  },
]

const router = createRouter({
  mode: "history",
  history: createWebHistory(process.env.BASE_URL),
  routes,
  // scrollBehavior: (to, from, savedPosition) => {
  //   if (savedPosition) {
  //     return savedPosition
  //   } else if (to.hash) {
  //     return {
  //       selector: to.hash,
  //     }
  //   } else {
  //     return { x: 0, y: 0 }
  //   }
  // },
})

router.beforeEach(function (to, from, next) {
  if (to.meta.transition != "none")
    // console.log(to.meta.transition != "none")
    store.dispatch("setPagesTransition", true)

  setTimeout(() => {
    window.scrollTo(0, 0)
  }, 100)

  setTimeout(() => {
    store.dispatch("setPagesTransition", false)
  }, 352.5)

  next()
})

router.afterEach(() => {})

export default router
