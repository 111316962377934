<template>
  <footer
    :class="{
      footer: true,
      '--show': !pagesTransition,
      '--hide': pagesTransition,
    }"
  >
    <div>
      <router-link to="/">159F</router-link>
    </div>
    <div class="footer__menu">
      <ul class="no-style">
        <li>
          <router-link to="/about">About</router-link>
        </li>
        <li>
          <router-link to="/artists">Artists</router-link>
        </li>
        <!-- <li>
          <router-link to="/multimedia">Multimedia</router-link>
        </li> -->
        <li>
          <router-link to="/exhibitions">Exhibitions</router-link>
        </li>
        <li>
          <router-link to="/fairs">Fairs</router-link>
        </li>
        <li>
          <router-link to="/catalogue">Catalogue</router-link>
        </li>
        <li>
          <router-link to="/contacts">Contacts</router-link>
        </li>
      </ul>
    </div>
    <div class="footer__copy">
      <a target="_blank" href="https://madeinburo.com">(С) Made in Buro</a>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterBlock",
  components: {},
  computed: {
    pagesTransition() {
      return this.$store.getters.getPagesTransition()
    },
  },
}
</script>
