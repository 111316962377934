import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import "./styles/main.scss"
import VueLazyload from "vue-lazyload"

createApp(App)
  .use(store)
  .use(router)
  .use(VueLazyload, { preLoad: 0.9 })
  .mount("#app")
